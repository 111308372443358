import zglRequest from './request'

export const exportfn = (params = {}) => zglRequest.downFile({ url: '/bill/downExcel', params })

export const downFile = (content: any, filename: any) => {
  // 创建隐藏的可下载链接
  var eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  // 字符内容转变成blob地址
  var blob = new Blob([content])
  eleLink.href = URL.createObjectURL(blob)
  document.body.appendChild(eleLink)
  // 触发点击
  eleLink.click()
  // 然后移除
  document.body.removeChild(eleLink)
}
