import { IForm } from '@/base-ui/form/types'

export const searchFormConfig: IForm = {
  formItems: [
    {
      field: 'water_num',
      type: 'input',
      placeholder: '流水号'
    },
    {
      field: 'order_id',
      type: 'input',
      placeholder: '关联订单'
    },
    {
      field: 'business_name',
      type: 'input',
      placeholder: '商家'
    },
    {
      field: 'customer_name',
      type: 'input',
      placeholder: '客户'
    }
  ]
}
