export const contentTableConfig = {
  propList: [
    { prop: 'water_num', label: '流水号' },
    { prop: 'order_id', label: '关联订单' },
    { label: '来源渠道', slotName: 'origin' },
    { label: '业务', slotName: 'status' },
    { label: '金额', slotName: 'money' },
    { prop: 'business_name', label: '商家信息' },
    { label: '下单客户手机号/姓名', slotName: 'customer' },
    { prop: 'create_time', label: '创建时间' }
  ]
}
